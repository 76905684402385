.rdw-option-wrapper {
  color: black;
}

.rdw-dropdown-wrapper {
  color: black;
}

.public-DraftStyleDefault-block {
  margin: 0em 0;
}

.public-DraftStyleDefault-ol,
.public-DraftStyleDefault-ul {
  margin: 0px 0;
}

.rdw-link-modal {
  color: black;
}

.rdw-link-modal-input {
  border: 1px solid;
}

@media only screen and (min-width: 554px) {
  .rdw-link-modal {
    left: -225px;
  }
}

@media only screen and (max-width: 560px) {
  .rdw-link-modal {
    left: 5px;
  }
}

@media only screen and (max-width: 420px) {
  .rdw-link-modal {
    left: -40px;
  }
}

.rdw-option-wrapper:hover {
  box-shadow: 0px 0px 0px;
}

.rdw-option-active {
  box-shadow: 0px 0px 0px;
  background-color: #bfbdbd;
  border: 0px;
}
